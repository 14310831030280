import React, { useEffect, useRef, useState, Suspense } from "react";
import { lightTheme } from "./components/Themes";
import GlobalStyle from "./globalStyle";
import { ThemeProvider } from "styled-components";
import {
  Switch,
  Route,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import SoundBar from "./subComponents/SoundBar";
import Contact from "./components/Contact";
import Loader from "./components/Loader";
// import SEO from "./components/SEO";
import ScreenVideoComponent from "./components/ScreenVideoComponent";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";

// Import the 404 page component
import NotFoundPage from "./components/404";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

const Main = React.lazy(() => import("./components/Main"));
const BlogPage = React.lazy(() => import("./components/Blog"));
const WorkPage = React.lazy(() => import("./components/Work"));
const AboutPage = React.lazy(() => import("./components/About"));
const MySkillsPage = React.lazy(() => import("./components/MySkills"));
const MyBlog = React.lazy(() => import("./components/MyBlog"));
const BlogDetail = React.lazy(() => import("./components/BlogDetail"));
const SnakeGame = React.lazy(() => import("./components/SnakeGame"));

// Initialize Google Analytics
ReactGA.initialize("G-3N1XV0V5J1"); // Replace with your GA4 Measurement ID

const handleDownloadClick = () => {
  // Send custom event to Google Analytics
  ReactGA.event({
    category: "User",
    action: "Download Resume",
    label: "Resume Download Button",
  });
};

function App() {
  const location = useLocation();

  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    // Function to update screen width
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 5000);

    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    }

    return () => clearTimeout(timer);
  }, []);

  const containerVariants = {
    hidden: { y: 0 },
    visible: { y: "-100%" },
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <>
      {/* <SEO /> */}
      <Suspense fallback={<Loader />}>
        <HelmetProvider>
          {location.pathname === "/" && (
            <ScreenVideoComponent
              isVisible={isVisible}
              containerVariants={containerVariants}
              videoRef={videoRef}
            />
          )}
          <GlobalStyle />
          <ThemeProvider theme={lightTheme}>
            <SoundBar />
            <AnimatePresence exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                <Route exact path="/" component={Main} />
                {/* <Route exact path="/about" component={AboutPage} /> */}
                <Route
                  exact
                  path="/about"
                  render={(props) => (
                    <AboutPage handleClick={handleDownloadClick} {...props} />
                  )}
                />
                <Route exact path="/project" component={BlogPage} />
                <Route exact path="/achievement" component={WorkPage} />
                <Route exact path="/skills" component={MySkillsPage} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/loader" component={Loader} />
                <Route exact path="/blog" component={MyBlog} />
                {isDesktop ? (
                    <Route exact path="/game" component={SnakeGame} />
                  ) : (
                    <Route
                      exact
                      path="/game"
                      render={() => <Redirect to="/" />} // Redirect mobile users to the homepage
                    />
                  )}
                <Route exact path="/blog/details/:id" component={BlogDetail} />
                {/* Catch-all route for 404 pages */}
                <Route path="*" component={NotFoundPage} />
              </Switch>
            </AnimatePresence>
          </ThemeProvider>
        </HelmetProvider>
      </Suspense>
    </>
  );
}

const Root = () => (
  <Router>
    <App />
  </Router>
);

export default Root;
